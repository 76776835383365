import BaseInstance from "../base.instance";
import {
  DATA_UTAMA_LOKASI_LIST,
  DATA_UTAMA_LOKASI_CREATE,
  DATA_UTAMA_LOKASI_UPDATE,
  DATA_UTAMA_LOKASI_DELETE
} from "../constants";

const DataUtamaLokasiService = {
  getList(data) {
    return BaseInstance.post(DATA_UTAMA_LOKASI_LIST, data);
  },
  create(data) {
    return BaseInstance.post(DATA_UTAMA_LOKASI_CREATE, data);
  },
  update(data) {
    return BaseInstance.post(DATA_UTAMA_LOKASI_UPDATE, data);
  },
  delete(data) {
    return BaseInstance.post(DATA_UTAMA_LOKASI_DELETE, data);
  }
};

export default DataUtamaLokasiService;
