<template>
  <div>
    <v-row class="mx-1">
      <v-col cols="12">
        <v-card id="card-custom" class="px-2 py-4 rounded-lg">
          <v-card-actions>
            <p class="headline-color px-2">Data Lokasi</p>
          </v-card-actions>
          <v-divider />
          <v-row class="mx-2" justify="end">
            <v-col cols="12" xl="3" lg="3" md="6" sm="6" xs="12" class="pb-0">
              <v-autocomplete
                :items="unitLists"
                v-model="filter.unit_utama_id"
                :loading="loadingUnit"
                placeholder="Unit Utama"
                outlined
                dense
                hide-details
                item-text="singkatan"
                item-value="unit_utama_id"
                clearable
                @change="changeUnit(0)"
                @click:clear="changeUnit(0)"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" xl="3" lg="3" md="6" sm="6" xs="12" class="pb-0">
              <v-autocomplete
                :items="unitLists2"
                v-model="filter.unit_kerja_2_id"
                :loading="loadingUnit"
                placeholder="Unit Kerja 2"
                outlined
                dense
                hide-details
                item-text="singkatan"
                item-value="unit_kerja_2_id"
                clearable
                @change="changeUnit(1)"
                @click:clear="changeUnit(1)"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" xl="3" lg="3" md="6" sm="6" xs="12" class="pb-0">
              <v-autocomplete
                :items="unitLists3"
                v-model="filter.unit_kerja_3_id"
                :loading="loadingUnit"
                placeholder="Unit Kerja 3"
                outlined
                dense
                hide-details
                item-text="singkatan"
                item-value="unit_kerja_3_id"
                clearable
                @change="changeUnit(2)"
                @click:clear="changeUnit(2)"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" xl="3" lg="3" md="6" sm="6" xs="12" class="pb-0">
              <v-autocomplete
                :items="unitLists4"
                v-model="filter.unit_kerja_4_id"
                :loading="loadingUnit"
                placeholder="Unit Kerja 4"
                outlined
                dense
                hide-details
                item-text="singkatan"
                item-value="unit_kerja_4_id"
                clearable
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-card-actions>
            <v-btn
              text
              class="text-capitalize caption headline-color"
              color="primary"
              @click="
                visible = true;
                isEdit = false;
                form.id = null;
              "
              >Buat Lokasi<v-icon class="ml-2" color="primary"
                >add_circle</v-icon
              ></v-btn
            >
            <v-spacer />
            <v-col cols="12" xl="3" lg="3" md="6" sm="6" xs="12" class="my-0">
              <v-text-field
                v-model="filter.search"
                placeholder="Cari"
                outlined
                dense
                hide-details
                prepend-inner-icon="search"
              ></v-text-field>
            </v-col>
          </v-card-actions>
          <v-data-table
            id="table-custom"
            :headers="headers"
            :items="locations"
            :loading="loading"
            :options.sync="options"
            :server-items-length="pagination.totalItem"
            :footer-props="{
              'items-per-page-options': rowsPerPageItems
            }"
            loading-text="Loading... Please wait"
          >
            <template v-slot:[`item.unit`]="{ item }">
              <div class="py-1" style="min-width: 150px">
                <p class="mb-0"><b>Unit Utama: </b>{{ item.s_unit_utama }}</p>
                <p class="mb-0">
                  <b>Unit Kerja 2: </b>{{ item.s_unit_kerja_2 }}
                </p>
                <p class="mb-0">
                  <b>Unit Kerja 3: </b>{{ item.s_unit_kerja_3 }}
                </p>
              </div>
            </template>
            <template v-slot:[`item.lokasi_1`]="{ item }">
              <div class="py-1" style="min-width: 150px" v-if="item.lokasi1">
                <p class="mb-0">
                  <b>Latitude: </b>{{ item.lokasi1.coordinates[1] }}
                </p>
                <p class="mb-0">
                  <b>Longitude: </b>{{ item.lokasi1.coordinates[0] }}
                </p>
                <a
                  class="hover-primary caption"
                  :href="
                    `https://www.google.com/maps/search/?api=1&query=${item.lokasi1.coordinates[1]},${item.lokasi1.coordinates[0]}`
                  "
                  target="_blank"
                  >Lihat</a
                >
              </div>
            </template>
            <template v-slot:[`item.lokasi_2`]="{ item }">
              <div class="py-1" style="min-width: 150px" v-if="item.lokasi2">
                <p class="mb-0">
                  <b>Latitude: </b>{{ item.lokasi2.coordinates[1] }}
                </p>
                <p class="mb-0">
                  <b>Longitude: </b>{{ item.lokasi2.coordinates[0] }}
                </p>
                <a
                  class="hover-primary caption"
                  :href="
                    `https://www.google.com/maps/search/?api=1&query=${item.lokasi2.coordinates[1]},${item.lokasi2.coordinates[0]}`
                  "
                  target="_blank"
                  >Lihat</a
                >
              </div>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-btn icon small color="primary" @click="handleEdit(item)"
                ><v-icon small>edit</v-icon></v-btn
              >
              <v-btn icon small color="error" @click="handleDelete(item)"
                ><v-icon small>delete</v-icon></v-btn
              >
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="visible" persistent max-width="700">
      <v-card class="pa-5 rounded-lg" id="card-custom">
        <v-btn @click="visible = false" x-small icon class="float-right">
          <v-icon>close</v-icon>
        </v-btn>
        <v-card-text class="subtitle-2 px-0 font-weight-regular">
          {{ isEdit ? "Ubah" : "Buat" }} Data Lokasi
        </v-card-text>
        <v-divider class="mb-4" />
        <v-form
          id="form"
          ref="form"
          v-model="valid"
          lazy-validation
          @submit.prevent="save()"
        >
          <v-row>
            <v-col cols="5" class="py-1">
              <v-subheader class="caption headline-color font-weight-regular"
                >Unit Utama</v-subheader
              >
            </v-col>
            <v-col cols="7" class="py-1">
              <v-select
                :items="unitForms"
                v-model="form.unit_utama_id"
                :loading="loadingUnit"
                outlined
                dense
                item-text="singkatan"
                item-value="unit_utama_id"
                placeholder="Unit Utama"
                @change="changeUnitForm(0)"
                @click:clear="changeUnitForm(0)"
                :rules="[v => !!v || 'Unit Utama harus diisi']"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="5" class="py-1">
              <v-subheader class="caption headline-color font-weight-regular"
                >Unit Kerja 2</v-subheader
              >
            </v-col>
            <v-col cols="7" class="py-1">
              <v-select
                :items="unitForms2"
                v-model="form.unit_kerja_2_id"
                :loading="loadingUnit"
                outlined
                dense
                item-text="singkatan"
                item-value="unit_kerja_2_id"
                placeholder="Unit Kerja 2"
                @change="changeUnitForm(1)"
                @click:clear="changeUnitForm(1)"
                :rules="[v => !!v || 'Unit Kerja 2 harus diisi']"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="5" class="py-1">
              <v-subheader class="caption headline-color font-weight-regular"
                >Unit Kerja 3</v-subheader
              >
            </v-col>
            <v-col cols="7" class="py-1">
              <v-select
                :items="unitForms3"
                v-model="form.unit_kerja_3_id"
                :loading="loadingUnit"
                outlined
                dense
                item-text="singkatan"
                item-value="unit_kerja_3_id"
                placeholder="Unit Kerja 3"
                @change="changeUnitForm(2)"
                @click:clear="changeUnitForm(2)"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="5" class="py-1">
              <v-subheader class="caption headline-color font-weight-regular"
                >Unit Kerja 4</v-subheader
              >
            </v-col>
            <v-col cols="7" class="py-1">
              <v-select
                :items="unitForms4"
                v-model="form.unit_kerja_4_id"
                :loading="loadingUnit"
                outlined
                dense
                item-text="singkatan"
                item-value="unit_kerja_4_id"
                placeholder="Unit Kerja 4"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="5" class="py-1">
              <v-subheader class="caption headline-color font-weight-regular"
                >Nama Lokasi 1</v-subheader
              >
            </v-col>
            <v-col cols="7" class="py-1">
              <v-text-field
                v-model="form.nama_lokasi1"
                outlined
                dense
                placeholder="Nama Lokasi"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="5" class="py-1">
              <v-subheader class="caption headline-color font-weight-regular"
                >Lokasi 1</v-subheader
              >
            </v-col>
            <v-col cols="7" class="py-1">
              <v-text-field
                v-model="form.lokasi1_lat"
                outlined
                dense
                placeholder="Latitude"
                :rules="[v => !!v || 'Lokasi harus diisi']"
              ></v-text-field>
              <v-text-field
                v-model="form.lokasi1_long"
                outlined
                dense
                placeholder="Longitude"
                :rules="[v => !!v || 'Lokasi harus diisi']"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="5" class="py-1">
              <v-subheader class="caption headline-color font-weight-regular"
                >Nama Lokasi 2</v-subheader
              >
            </v-col>
            <v-col cols="7" class="py-1">
              <v-text-field
                v-model="form.nama_lokasi2"
                outlined
                dense
                placeholder="Nama Lokasi"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="5" class="py-1">
              <v-subheader class="caption headline-color font-weight-regular"
                >Lokasi 2</v-subheader
              >
            </v-col>
            <v-col cols="7" class="py-1">
              <v-text-field
                v-model="form.lokasi2_lat"
                outlined
                dense
                placeholder="Latitude"
              ></v-text-field>
              <v-text-field
                v-model="form.lokasi2_long"
                outlined
                dense
                placeholder="Longitude"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-card-actions class="mt-3">
            <v-spacer />
            <v-btn
              small
              outlined
              color="#717171"
              class="px-12"
              @click="visible = false"
              >Batal</v-btn
            >
            <v-btn
              small
              color="#FBB005"
              class="px-12 subtitle-2 text-capitalize"
              :loading="formLoading"
              type="submit"
              :disabled="!valid"
              form="form"
              >Simpan</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import DataUtamaService from "@/services/resources/data.utama.service";
import DataUtamaLokasiService from "@/services/resources/data.utama.lokasi.service";

export default {
  data() {
    return {
      visible: false,
      valid: false,
      isEdit: false,
      loading: false,
      formLoading: false,
      loadingUnit: false,
      unitLists: [],
      unitLists2: [],
      unitLists3: [],
      unitLists4: [],
      unitForms: [],
      unitForms2: [],
      unitForms3: [],
      unitForms4: [],
      filter: {
        search: null,
        unit_utama_id: null,
        unit_kerja_2_id: null,
        unit_kerja_3_id: null,
        unit_kerja_4_id: null
      },
      headers: [
        {
          text: "No",
          value: "no",
          sortable: false
        },
        {
          text: "Unit",
          value: "unit",
          sortable: false
        },
        {
          text: "Nama Lokasi 1",
          value: "nama_lokasi1",
          sortable: false
        },
        {
          text: "Lokasi 1",
          value: "lokasi_1",
          sortable: false
        },
        {
          text: "Nama Lokasi 2",
          value: "nama_lokasi2",
          sortable: false
        },
        {
          text: "Lokasi 2",
          value: "lokasi_2",
          sortable: false
        },
        { text: "", value: "actions", sortable: false, align: "center" }
      ],
      locations: [],
      pagination: {
        page: 1,
        itemsPerPage: 10,
        totalItem: 0,
        pageCount: 0
      },
      options: {
        sortBy: ["unit_utama_id"],
        sortDesc: [false]
      },
      rowsPerPageItems: [10, 20, 30, 40],
      fileImport: null,
      form: {
        id: null,
        unit_utama_id: null,
        unit_kerja_2_id: null,
        unit_kerja_3_id: null,
        unit_kerja_4_id: null,
        nama_lokasi1: null,
        lokasi1_lat: null,
        lokasi1_long: null,
        nama_lokasi2: null,
        lokasi2_lat: null,
        lokasi2_long: null
      }
    };
  },
  watch: {
    options: {
      handler() {
        this.fetchListDebounce(this.getLocationList);
      },
      deep: true
    },
    paginationProperty: function() {
      this.options.page = 1;
      this.fetchListDebounce(this.getLocationList);
    },
    visible(val) {
      if (!val) {
        this.$refs.form.reset();
      }
    },
    visibleImport(val) {
      if (!val) {
        this.fileImport = null;
        this.$refs.fileDraggable.removeFile();
        this.$refs.fileDraggable.closeAlert();
      }
    }
  },
  computed: {
    paginationProperty() {
      return [
        this.filter.search,
        this.filter.unit_utama_id,
        this.filter.unit_kerja_2_id,
        this.filter.unit_kerja_3_id,
        this.filter.unit_kerja_4_id
      ].join();
    }
  },
  methods: {
    handleEdit(item) {
      this.visible = true;
      this.isEdit = true;
      this.form = {
        id: item.id,
        unit_utama_id: item.unit_utama_id,
        unit_kerja_2_id: item.unit_kerja_2_id,
        unit_kerja_3_id: item.unit_kerja_3_id,
        unit_kerja_4_id: item.unit_kerja_4_id,
        nama_lokasi1: item.nama_lokasi1,
        lokasi1_lat: item.lokasi1 ? item.lokasi1.coordinates[1] : "",
        lokasi1_long: item.lokasi1 ? item.lokasi1.coordinates[0] : "",
        nama_lokasi2: item.nama_lokasi2,
        lokasi2_lat: item.lokasi2 ? item.lokasi2.coordinates[1] : "",
        lokasi2_long: item.lokasi2 ? item.lokasi2.coordinates[0] : ""
      };
      this.populateUnit();
    },
    handleDelete(item) {
      this.$confirm({
        title: "Confirm",
        message: `Apakah anda yakin akan menghapus data ini?`,
        button: {
          no: "Batal",
          yes: "Ya"
        },
        callback: confirm => {
          if (confirm) {
            let payload = {
              id: item.id
            };
            this.deleteLocation(payload);
          }
        }
      });
    },
    save() {
      if (this.$refs.form.validate()) {
        if (this.form.id) {
          this.updateLocation({
            ...this.form
          });
        } else {
          this.createLocation({
            ...this.form
          });
        }
      }
    },
    changeUnit(type = 0) {
      if (type == 0) {
        this.unitLists2 = this.unitLists.find(
          d => d.unit_utama_id == this.filter.unit_utama_id
        )?.unit_kerja2;
        this.filter.unit_kerja_2_id = null;
        this.filter.unit_kerja_3_id = null;
      } else if (type == 1) {
        this.unitLists3 = this.unitLists2.find(
          d => d.unit_kerja_2_id == this.filter.unit_kerja_2_id
        )?.unit_kerja3;
        this.filter.unit_kerja_3_id = null;
      } else if (type == 2) {
        this.unitLists4 = this.unitLists3.find(
          d => d.unit_kerja_3_id == this.filter.unit_kerja_3_id
        )?.unit_kerja4;
        this.filter.unit_kerja_4_id = null;
      }
    },
    changeUnitForm(type = 0) {
      if (type == 0) {
        this.unitForms2 = this.unitForms.find(
          d => d.unit_utama_id == this.form.unit_utama_id
        )?.unit_kerja2;
        this.form.unit_kerja_2_id = null;
        this.form.unit_kerja_3_id = null;
      } else if (type == 1) {
        this.unitForms3 = this.unitForms2.find(
          d => d.unit_kerja_2_id == this.form.unit_kerja_2_id
        )?.unit_kerja3;
        this.form.unit_kerja_3_id = null;
      } else if (type == 2) {
        this.unitForms4 = this.unitForms3.find(
          d => d.unit_kerja_3_id == this.form.unit_kerja_3_id
        )?.unit_kerja4;
        this.form.unit_kerja_4_id = null;
      }
    },
    populateUnit() {
      this.unitForms2 = this.unitForms.find(
        d => d.unit_utama_id == this.form.unit_utama_id
      )?.unit_kerja2;
      this.unitForms3 = this.unitForms2.find(
        d => d.unit_kerja_2_id == this.form.unit_kerja_2_id
      )?.unit_kerja3;
      this.unitForms4 = this.unitForms3.find(
        d => d.unit_kerja_3_id == this.form.unit_kerja_3_id
      )?.unit_kerja4;
    },
    handleOnChange(val) {
      this.fileImport = val;
    },
    // Service
    async getListUnit() {
      try {
        this.loadingUnit = true;
        await DataUtamaService.getUnitKerjaAll({
          platform: this.isPresensi ? "presensi" : "simpeg"
        })
          .then(response => {
            const { status, data } = response.data;
            if (status) {
              this.unitLists = data;
              this.unitForms = data;
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loadingUnit = false));
      } catch (err) {
        console.error(err);
      }
    },
    async getLocationList() {
      const { page, itemsPerPage, sortBy, sortDesc } = this.options;
      let orderBy = [];
      sortBy.map((d, i) => {
        let sort = sortDesc[i] ? "desc" : "asc";
        orderBy = [...orderBy, d, sort];
        return d;
      });
      try {
        this.loading = true;
        await DataUtamaLokasiService.getList({
          filter: {
            search: this.filter.search,
            unit_utama: this.filter.unit_utama_id,
            unit_kerja_2: this.filter.unit_kerja_2_id || null,
            unit_kerja_3: this.filter.unit_kerja_3_id || null,
            unit_kerja_4: this.filter.unit_kerja_4_id || null
          },
          orderBy,
          page: page,
          perPage: itemsPerPage
        })
          .then(response => {
            const { status, data } = response.data;
            const { data: list, last_page, total } = data;
            if (status) {
              let locations = list;
              locations.map((data, index) => {
                data.no = itemsPerPage * (page - 1) + (index + 1);
                return data;
              });

              this.locations = locations;
              this.pagination.totalItem = parseInt(total);
              this.pagination.pageCount = parseInt(last_page);
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loading = false));
      } catch (err) {
        console.error(err);
      }
    },
    async createLocation(data) {
      try {
        this.formLoading = true;
        await DataUtamaLokasiService.create(data)
          .then(response => {
            const { status, message } = response.data;
            if (status) {
              this.getLocationList();
              this.visible = false;
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message: message,
                color: "success"
              });
            } else {
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message: message,
                color: "error"
              });
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => {
            this.formLoading = false;
          });
      } catch (err) {
        console.error(err);
      }
    },
    async updateLocation(data) {
      try {
        this.formLoading = true;
        await DataUtamaLokasiService.update(data)
          .then(response => {
            const { status, message } = response.data;
            if (status) {
              this.getLocationList();
              this.visible = false;
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message: message,
                color: "success"
              });
            } else {
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message: message,
                color: "error"
              });
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => {
            this.formLoading = false;
          });
      } catch (err) {
        console.error(err);
      }
    },
    async deleteLocation(payload) {
      try {
        this.formLoading = true;
        await DataUtamaLokasiService.delete(payload)
          .then(response => {
            const { status, message } = response.data;
            if (status) {
              this.getLocationList();
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message: message,
                color: "success"
              });
            } else {
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message: message,
                color: "error"
              });
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.formLoading = false));
      } catch (err) {
        console.error(err);
      }
    }
  },
  mounted() {
    this.getListUnit();
  }
};
</script>
<style lang="scss">
#table-custom.v-data-table {
  color: #717171 !important;
  .v-data-table__wrapper > table > thead {
    background-color: #fdfdfd;
  }
  .v-data-table__wrapper > table > thead > tr > th {
    font-size: 0.775rem;
    font-weight: 400;
  }
  .v-data-table__wrapper > table > tbody > tr > td {
    font-size: 0.775rem;
  }
}
#card-custom .v-input {
  font-size: 0.775rem;
}
</style>
